import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import OlimpiadaPoketCoronaVirus from '../components/OlimpiadaPoketCoronaVirus'
import Footer from '../components/Footer'
import Calendar from '../images/calendar.svg'
import ArrowDown from '../images/arrowDown.png'

import {
  header,
  bg1,
  btn,
  title,
  arrow
} from '../components/styles/InternalPages.styles'

const StudentsPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Olimpíada Pocket" />
    <NavBar location={props.location} />
    <div css={header}>
      <div css={bg1} style={{ marginTop: 84 }}>
        <div style={{ paddingTop: 40, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection:'column' }}>
          <div css={title}>Olimpíada Pocket Coronavírus</div>
          <h2><img src={Calendar} alt='calendar' /> 29 de Março</h2>
          <h1>APURAR, CHECAR,<br /> APRENDER E INFORMAR.</h1>
          <div css={btn}>FAÇA SUA INSCRIÇÃO</div>
          <div css={arrow} style={{ marginTop: 190 }}>
            Saiba mais<br />
            <img src={ArrowDown} alt='/' />
          </div>
        </div>
      </div>
      <OlimpiadaPoketCoronaVirus />
    </div>
    <Footer />
  </Fragment>
)

export default StudentsPage
