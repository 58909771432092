import React, { Fragment } from 'react'

import {
  inspired,
  container,
  contentView,
  columns, 
  column,
  visionBox
} from './styles/EstudeAqui.styles'

import {
  btn,
} from '../components/styles/InternalPages.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired}>
      <div css={container}>
        <div css={visionBox}>
          <div css={columns}>
            <div css={column}>
              <div css={contentView}>
                <h2>
                  POCKET<br /> CORONAVÍRUS
                </h2>
                <p style={{ marginBottom: 36 }}>Quando surgiam os primeiros casos de COVID-19 no Brasil, ainda em março de 2020, as pessoas estavam sedentas por informação sobre a doença, sobre as precauções diante dela, sobre o histórico de grandes epidemias que assolaram o mundo, e foi neste cenário que realizamos a primeira Pocket, sobre o Coronavírus. Lançamos mão das principais fontes de informações na área da saúde e relacionamos o tema com aspectos biológicos, históricos, sociológicos, para realiza-la, que também tinha no seu objetivo levar informação à população. As informações e o conhecimento sobre o COVID-19 mudam velozmente, e certamente muito já aprendemos desde então – talvez seja o momento de uma nova pocket sobre COVID-19. </p>
                <a href='https://fractalid.fractaltecnologia.com.br/?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn} style={{ margin: '0 auto' }}>ACESSE A PROVA</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default InspiredSite
